<template>
  <div class="jumbotron p-5 rounded-lg text-center" style="margin-bottom: 2em">
    <h1>Friends</h1>
    <h3>of the</h3>
    <h2>Rauchholz Memorial Library</h2>
  </div>

  <div class="container">
    <div class="row">
      <div class="text-center">
        <div class="row">
          <h2>What is the Friends group?</h2>
          <p>The Friends is a group of volunteers who assist the library in various projects. The group is a non-profit corporation with 501(c)(3) status that allows the group to apply for grant money to help fund library projects and events. With their help, the library is able to purchase new items that benefit the community's use of the library.</p>

          <table class="table table-hover">
            <h2>Board of Directors</h2>
            <tbody>
            <tr>
              <td>Heidi Kanouse</td>
            </tr>
            <tr>
              <td>Marjorie Morrison</td>
            </tr>
            <tr>
              <td>Annette Bontrager</td>
            </tr>
            </tbody>
          </table>

          <br>

          <table class="table table-hover">
            <h2>Officers</h2>
            <tbody>
            <tr>
              <td>President</td>
              <td>Walt Cramer</td>
            </tr>
            <tr>
              <td>Vice President</td>
              <td>Betty Claus</td>
            </tr>
            <tr>
              <td>Treasurer</td>
              <td>Audrey Jeschke</td>
            </tr>
            <tr>
              <td>Secretary</td>
              <td>Renee Herhold</td>
            </tr>
            </tbody>
          </table>
          <br>
          <h2>The Friends group has achieved the following:</h2>
          <table class="table table-hover">
            <tbody>
            <tr>
              <td>2017</td>
              <td>$1000.00 Alice E. Turner Memorial Trust</td>
            </tr>
            <tr>
              <td>2017</td>
              <td>$10000.00 Jury Foundation</td>
            </tr>
            <tr>
              <td>2017</td>
              <td>$10000.00 Dow Chemical Company Foundation Community Donor Advised</td>
            </tr>
            <tr>
              <td>2016</td>
              <td>$20000.00 Rollin M. Gerstacker</td>
            </tr>
            <tr>
              <td>2016</td>
              <td>$10000.00 Morley Foundation</td>
            </tr>
            <tr>
              <td>2016</td>
              <td>$50000.00 Frances Goll Mills Fund (Huntington Bank)</td>
            </tr>
            <tr>
              <td>2016</td>
              <td>$100000.00 Harvey Randall Wickes Foundation</td>
            </tr>
            <tr>
              <td>2013</td>
              <td>Sponsored "Geek the Library" program</td>
            </tr>
            <tr>
              <td>2012</td>
              <td>Margart Stoker Grant for Graphic Novels</td>
            </tr>
            <tr>
              <td>2012</td>
              <td>Purchase of a fireproof legal size file cabinet for the Hemlock History Collection</td>
            </tr>
            <tr>
              <td>2010</td>
              <td>$5000.00 Herbert H. & Grace A. Dow Foundation for purchase of juvenile non-fiction collection books on religions, holidays, Africian-American history, economics, disabilities, families and the social sciences</td>
            </tr>
            <tr>
              <td>2010</td>
              <td>$2000.00 Michael Jeffers Family Memorial Fund for juvenile biographies</td>
            </tr>
            <tr>
              <td>2010</td>
              <td>$2000.00 Friends' fundraisier donation to library for purchase of books</td>
            </tr>
            <tr>
              <td>2009</td>
              <td>$1000.00 Terrance & Barbara Kaiser Foundation grant for audio books</td>
            </tr>
            <tr>
              <td>2009</td>
              <td>Harvey Randall Wickes grant for large print books and audio books </td>
            </tr>
            <tr>
              <td>2009</td>
              <td>$2000.00 Friends' fundraiser donation to library for purchase of books</td>
            </tr>
            <tr>
              <td>2008</td>
              <td>Circulation computer, upgrade circulation computer programming, Summer 2008 Reading program supplies</td>
            </tr>
            <tr>
              <td>2007</td>
              <td>$2000.00 Gerstacker grant for Acclerated Books. Nearly 300 books were purchased with this grant</td>
            </tr>
            <tr>
              <td>2007</td>
              <td>World Book Set of Encyclopedias</td>
            </tr>
            <tr>
              <td>2005</td>
              <td>Target Community grant received in September allowing the friends to purchase bean bags, flannel story board kits, books on cd and cassettes and the Clifford rug to be used for the library's story hour program</td>
            </tr>
            <tr>
              <td></td>
              <td>Book cart for Board books in the children's section of the library</td>
            </tr>
            <tr>
              <td></td>
              <td>Red bookcase for magazines in the juvenile section of the library</td>
            </tr>
            </tbody>
          </table>
          <br>
          <h4><b>The Friends is always seeking new members; especially those willing to serve in an officer position.</b></h4>
          <h4><b>If you are interested in joining visit us, or call us at (989) 642-8621</b></h4>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Friends'
}
</script>

<style scoped>
.jumbotron {
  background-color: #7a9c4e;
  color: #FFFFFF;
}

.table {
  padding: 9em;
}

a {
  color: #FFFFFF;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}
</style>
